import React from 'react';
import { Routes, Route, Navigate, Router } from 'react-router-dom';
import Dashboard from './Dashboard';
import Registration from './Registration';
import UserSettings from './UserSettings';
import ProductManagement from './ProductManagement';
import StoreSettings from './StoreSettings';
import AdminDashboard from './Admin';
import AgreementList from './Agreementlist';
import DealerManagement from './DealerManagement';
import ServiceManagement from './ServiceManagement';
import AgreementsManagement from './Agreements';
import AddAgreement from './AddAgreement';
import AgreementsView from './AgreementView';
import DeliveryNoteCreation from './DeliveryNoteCreate';
import DeliveryNoteOverview from './DeliveryNote';
import SalesOverview from './SalesOverview';
import DeliveryNoteStore from './DevlieryNoteStore';
import DeliveryNoteCheck from './DeliveryNoteStoreCheck';
import PasswordReset from './PasswordReset';
import ForgotPassword from './PasswordReset_ByUser';
import ProtectedRoute from './ProtectedRoute';
import AdminRoute from './AdminRoute';
import Login from './Login';
import HeaderNav from './HeaderNav';
import styled from 'styled-components';
import { AuthProvider } from './Auth';
import InventoryOverview from './InventoryOverview';
import ProductOverview from './ProductOverview';
import BillingOverview from './BillingOverview';
import DealerBillingOverview from './DealerBillingOverview';
import InvoiceSettings from './InvoiceSettings'; // Importieren der InvoiceSettings-Komponente
import ResendActivation from './Login_ResendActivation';
import PriceAdjustments from './PriceAdjustments';

const Content = styled.main`
  flex: 1;
  padding: 20px;
  background: #f4f4f4;
`;


const AppRoutes = () => {
  return (
    <AuthProvider>

    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Registration />} />
      <Route path="/reset-password" element={<PasswordReset />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/resend-activation" element={<ResendActivation />} />
      <Route
        path="/*"
        element={
          <ProtectedRoute>
            <HeaderNav />
            <Content>
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/settings" element={<UserSettings />} />
                <Route path="/products" element={<ProductManagement />} />
                <Route path="/agreements-view" element={<AgreementsView />} />
                <Route path="/deliverynote-create" element={<DeliveryNoteCreation />} />
                <Route path="/deliverynote-edit/:id" element={<DeliveryNoteCreation />} />
                <Route path="/deliverynote" element={<DeliveryNoteOverview />} />
                <Route path="/deliverynotestore" element={<DeliveryNoteStore />} />
                <Route path="/deliverynotestore-check/:id" element={<DeliveryNoteCheck />} />
                <Route path="/inventoryoverview" element={<InventoryOverview />} />
                <Route path="/salesoverview" element={<SalesOverview />} />
                <Route path="/store-settings" element={<StoreSettings />} />
                <Route path="/dealer-management" element={<DealerManagement />} />
                <Route path="/service-management" element={<ServiceManagement />} />
                <Route path="/productoverview" element={<ProductOverview />} />
                <Route path="/agreements" element={<AgreementsManagement />} />
                <Route path="/add-agreement" element={<AddAgreement />} />
                <Route path="/edit-agreement/:id" element={<AddAgreement />} />
                <Route path="/agreement-list" element={<AgreementList />} />
                <Route path="/billingoverview" element={<BillingOverview />} />
                <Route path="/invoice-settings" element={<InvoiceSettings />} /> {/* Neue Route für InvoiceSettings */}
                <Route path="/dealer-billing" element={<DealerBillingOverview />} />
                <Route path="/price-adjustments" element={<PriceAdjustments />} />
                <Route path="*" element={<Navigate to="/home" />} />
                <Route
                  path="/admin/*"
                  element={
                    <AdminRoute>
                      <AdminDashboard />
                    </AdminRoute>
                  }
                />
              </Routes>
            </Content>
          </ProtectedRoute>
        }
      />
    </Routes>

    </AuthProvider>
  );
};

export default AppRoutes;
